.cookie {
  max-width: 480px;
  transform: translateY(100%);
}
@media (min-width: 576px) {
   .cookie {
    min-width: 480px;
  }
}
.cookie .show {
  transform: none;
}

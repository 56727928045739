


.input-relative-rp {
    position: relative;
  }
  
  .svgeye-absolute-rp {
    position: absolute;
    bottom: -6px;
    right: 9px;
  }
  
  .input-relative-rp2 {
    position: relative;
  }
  
  .svgeye-absolute-rp2 {
    position: absolute;
    bottom: -6px;
    right: 9px;
  }
@media screen and (max-width: 480px) {
  .text-year {
    padding-top: 9px !important;
  }
}

@media screen and (max-width: 480px) {
  .benefits-mob {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .commitments-mob {
    width: 100%;
    border-left: 1px solid;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .cost-mob {
    width: 100%;
    border-left: 1px solid;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .member-mob {
    width: 100%;
    border-left: 1px solid !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    text-align: center;
  }
}

.mo-tab {
  width: fit-content;
}

.mo-tab li:not(:first-child):not(:last-child) {
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.mo-tab li:first-child {
  border: 1px solid black;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.mo-tab li:first-child:active {
  border: 1px solid $primary !important;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}
.mo-tab li:last-child {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid;
  border-left: none;
}

.cc-click:active {
  background-color: #00999b33;
  color: #00999b !important;
  font-weight: bolder;
}

// .cc-click:focus {
//   background: #00999b33;
//   color: #00999b !important;
//   border-color:  #00999b !important;

// }

.noHover {
  pointer-events: none;
}

.cc-click-text:active {
  color: #00999b !important;
}

// .checkbox-menu li.active label:hover,
// .checkbox-menu li.active label:focus {
//   background-color: #b8b8ff;
// }

// .slide-hover {

//   .nav li a {
//     border: 0px solid transparent !important;
//   }
//   &:hover {

//     .border-color {
//        border: 0px; !important;
//
//     }

// .nav li:hover a {
//   border: 0px;
// }

// .nav li:focus a {
//   border: 0px;
//   border-color: #00999b;
// }

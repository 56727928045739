.cpd-absolute {
  position: absolute;
  border-radius: 50%;
  background: white;
  width: 20;
  width: 34px;
  height: 34px;
  align-content: center;
  height: 39ox;
}

@media screen and (max-width: 480px) {
  .media-direction {
    flex-direction: column;
  }

  .media-button {
    justify-content: center;
  }
}

.whitecircle-button {
  background-color: white;
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

/* Webkit-based browsers */
.scroll-effect::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
    height: 5px;
  }
  
  .scroll-effect::-webkit-scrollbar-track {
    background: #ccebeb; /* Background color of the track */
  }
  
  .scroll-effect::-webkit-scrollbar-thumb {
    background: $primary; /* Color of the thumb */
    border-radius: 3px; /* Rounded corners */
  }
  
  /* Mozilla Firefox */
  .scroll-effect::-moz-scrollbar {
    width: 5px; /* Width of the scrollbar */
    height: 5px;
  }
  
  .scroll-effect::-moz-scrollbar-track {
    background: $primary; /* Background color of the track */
  }
  
  .scroll-effect::-moz-scrollbar-thumb {
    background: $primary; /* Color of the thumb */
    border-radius: 3px; /* Rounded corners */
  }
  
  
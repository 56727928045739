.list-social {
    display: flex;
    .social-item {

    }
    .social-link {
        color: inherit;
        width: 2.5rem;
        height: 2.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;
        &:hover {
            color: $primary;
        }
    }
}

.card {
  --#{$prefix}card-inner-border-radius: #{$card-inner-border-radius};
}

.card-img-top {
  height: 13rem;
}

.card-img-right {
  width: 15rem;
  height: 15rem;
}

.card-img-fit {
  width: 13rem;
  height: 13rem;
  // width: auto;
}
// promo
.card-promo {
  border-color: transparent;
  margin-top: 1.875rem;
  padding-top: 2rem;
  flex-grow: 1;
  text-decoration: none !important;
  box-shadow: inset 0 0 0px 0 transparent;
  transition: box-shadow 0.3s ease;

  .card-icon-group {
    color: $white;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -1.5rem;
    width: 3.5rem;
    height: 3rem;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    box-shadow: 0 0 0 4px $white;
    z-index: 1;

    .icon-svg {
      font-size: 1.5rem;
    }
  }

  &-red {
    border-color: tint-color(#ff4646, 90%);
    background-color: tint-color(#ff4646, 90%);

    .card-icon-group {
      background-color: #ff4646;
    }

    .card-title {
      color: #ff4646;
    }
  }

  &-green {
    border-color: tint-color(#0ec781, 90%);
    background-color: tint-color(#0ec781, 90%);

    .card-icon-group {
      background-color: #0ec781;
    }

    .card-title {
      color: #0ec781;
    }
  }
  &:hover {
    border-color: $primary;
    box-shadow: inset 0 0 0px 1px $primary;
  }
}
// catgeory
.card-category {
  height: 100%;
  box-shadow: inset 0 0 0px 0 transparent;
  transition: box-shadow 0.3s ease;

  .card-fig {
    display: flex;
    min-height: 124px;
  }
  .card-img {
    width: auto;
    margin: auto;
    max-height: 90px;
  }

  .card-title {
    color: $body-color;
  }

  .card-link {
    text-decoration: none;
  }

  &:hover {
    border-color: $primary;
    box-shadow: inset 0 0 0px 1px $primary;

    .card-title {
      color: $primary;
    }
  }
}

.team-card {
  .card-fig {
    height: 220px;
    display: flex;
  }

  .card-img {
    width: auto;
    margin: auto auto 0 auto;
    max-height: 200px;
  }
}

// last order card
.last-order-card {
  transition: border 0.3s;

  &:hover {
    border-color: $primary;
  }

  .ratio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > * {
      width: auto;
      height: auto;
      max-width: 80%;
      max-height: 80%;
      top: auto;
      left: auto;
    }
  }
}

// product card
.product-card {
  transition: border 0.3s;
  background-color: #f9f9f9;

  &:hover {
    border-color: $primary;
  }

  .card-fig {
    display: flex;
    min-width: 122px;
  }
  .card-img {
    margin: auto;
    width: auto;
    max-width: 90px;
    max-height: 90px;
  }

  .card-info > span:not(:last-child)::after {
    content: "\2027";
    margin: 0 0.25rem;
    font-weight: 800;
  }

  .form-control-quantity {
    max-width: 10rem;
  }

  // .price-t {
  //     @include list-unstyled;
  //     display: flex;
  //     flex-direction: column;

  //     > &r {
  //         display: grid;
  //         grid-template-columns: 2fr repeat(6, minmax(1%,1fr));

  //     }
  //     &h,
  //     &d {
  //         padding: 0.5rem;
  //     }
  //     &h {
  //         flex: 0 1 30%;
  //     }
  //     &d {
  //         flex: 1 1 100%;
  //         max-width: 25%;
  //     }
  // }

  .price-table {
    table-layout: fixed;
    border-radius: $border-radius-lg;
    background-color: $white;
    border: 1px solid $gray-300;
    border-collapse: separate;

    // @include media-breakpoint-down(xl) {
    //     width: 100%;
    // }

    // @include media-breakpoint-up(sm) {
    //     border-radius: $border-radius-lg;
    // }

    th,
    td {
      padding: 0.375rem;
      font-size: $small-font-size;
      // @include media-breakpoint-up(md) {
      //     padding: .3755rem 1rem;
      // }
    }

    th {
      width: 145px;
    }

    td {
      font-weight: 800;
      width: 90px;
    }
    tr:hover {
      background-color: $light;
    }

    tr > th {
      font-weight: 600;
      text-transform: uppercase;
      font-size: $small-font-size;
      color: $text-muted;
    }

    tr:not(:last-child) > th,
    tr:not(:last-child) > td {
      border-bottom: 1px solid $gray-200;
    }
  }
}

// stats card
.row-stats {
  @include media-breakpoint-down(lg) {
    > .col-12 {
      justify-content: center;

      .card-stat {
        text-align: center;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    > .col-12:not(:first-child):not(:last-child) {
      justify-content: center;
    }

    > .col-sm-6:last-child .card-stat {
      margin-left: auto;
    }
  }
}

//  news list

.news-list {
  &-item:not(:last-child) {
    border-bottom: 1px solid $gray-500;
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
  }
}

.card-fig-top {
  @include border-top-radius(var(--#{$prefix}card-inner-border-radius));
}

//   event card
.card-event {
  .card-title {
    min-height: 48px;
  }
}
.card-tile {
  min-height: 160px;
  .card-fig {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    &::after {
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      background-color: rgba($color: $black, $alpha: 0.5);
    }
  }
  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .card-body {
    position: relative;
    z-index: 1;
  }
}

@media screen and (max-width: 480px) {
  .small-card {
  }
}

.hover-cards {
  .avatar-border {
    border: 3px solid transparent;
  }
  .border-color {
    border: 3px solid transparent;
  }

  &:hover {
    .avatar-border {
      border: 3px solid $primary;
      cursor: pointer;
      transition: 0.25s ease-in-out;
    }
    .border-color {
      border: 3px solid $primary;
      cursor: pointer;
      transition: 0.25s ease-in-out;
    }
  }
}

.text-limit-one {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-limit-two {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-limit-three {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-limit-four {
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-limit-ten {
  -webkit-line-clamp: 10;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slider-hover {
  .border-hover-pr {
    border: 3px solid transparent !important;
  }
  &:hover {
    .border-hover-pr {
      border: 3px solid $primary !important;
      transition: 0.25s ease-in-out;
    }
  }
}

.offcanvas.offcanvas-cart {
    width: 480px;
}

.offcanvas-body {
    position: relative;
}

.offcanvas-cart[data-loader] .offcanvas-body::before {
    content: "";
    width: 40px;
    height: 40px;
    border: 4px solid $gray-200;
    border-top: 4px solid $primary;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    animation-name: spinner_rotate;
    animation-duration: .7s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: 1;
    transition: opacity .15s, visibility .15s;
    opacity: 0;
    visibility: hidden;
}

.offcanvas-cart[data-loader]::after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: $white, $alpha: 0.5);
    position: absolute;
    transition: opacity .15s, visibility .15s;
    opacity: 0;
    visibility: hidden;
    z-index: 0;
}
.offcanvas-cart[data-loader="true"] .offcanvas-body::before {
    opacity: 1;
    visibility: visible;
}

.offcanvas-cart[data-loader="true"]::after {
    opacity: 1;
    visibility: visible;
}

.offcanvas-cart {

    .cart-list {
            &-item {
                padding: 1rem;
                margin-bottom: 1rem;
    
                &:not(:last-child) {
                    border-bottom: 1px solid $border-color;
                }
            }
        }
  
    .cart-product {
        display: flex;

        &-fig {
            display: flex;
            flex-shrink: 0;
            max-width: 70px;
            min-height: 90px;
            border: 1px $border-style $border-color;
            border-radius: $border-radius;
            padding: map-get($map: $spacers, $key: 4);
            margin-bottom: auto;
            @include media-breakpoint-up(md) {
                max-width: 90px;
                min-height: 122px;
            }
        }
        &-img {
            @include img-fluid;
            margin: auto;
        }

        &-details {
            margin-top: .25rem;
            flex: 1 1 auto;
            margin-left: map-get($map: $spacers, $key: 4);
        }
        &-title {
            font-size: map-get($map: $font-sizes, $key: 7)
        }
        &-link {
            color: $body-color;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        &-actions {
            display: flex;
            flex-wrap: wrap;
            margin-top: .25rem;
            justify-content: space-between;
        }
        &-quantity {
            width: auto;
            flex: 0 0 auto;
            margin-top: .875rem;
            margin-right: 2rem;
        }
        &-remove {
            margin-top: .875rem;
            color: $red;
            padding-left: .25rem;
            padding-right: .5rem;

            &:hover {
                color: #fff !important;
                border-color: $danger;
                background-color: shade-color($danger, $btn-hover-bg-shade-amount) !important;
            }
            &:focus {
                border-color: $danger;
            }
    
            &:focus-visible {
                outline: 0;
                box-shadow: 0 0 0 0.25rem rgba($red, .25);
              
            }

          > .icon-svg {
                font-size: 1.25rem;
          }
        }
    }

}

.offcanvas-header {
    position: relative;
    z-index: 1;
    border-bottom: 1px solid $gray-200;
  
    .cart-quantity-count {
        &::before {
            content: '(';
        }
        &::after {
            content: ')';
        }
    }
}


@keyframes spinner_rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}
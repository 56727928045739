

.modal-login,
.video-modal {

    .btn-close {
        position: absolute;
        height: 2.5rem;
        width: 2.5rem;
        background-color: $white;
        border-radius: 50%;
        top: -4rem;
        left: 50%;
        transform: translateX(-50%);
        opacity: 1;

        &:hover {
            opacity: 1;
         .btn-close-icon {
            color: $primary;
        }
        }
    }
}
:root {
    --bs-blue: #0030c2;
    --bs-indigo: #1e1b58;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #f2833b;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #00999b;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-lightbluebg: rgba(0, 153, 255, 0.1019607843);
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #00999b;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f2f5fc;
    --bs-dark: #212529;
    --bs-primary-rgb: 0, 153, 155;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 242, 245, 252;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: "Montserrat", system-ui, -apple-system, "Segoe UI",
      Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0)
    );
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #00999b;
    --bs-link-hover-color: #007a7c;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
  }
  
  .svg-symbols {
    position: absolute;
    z-index: -1;
    left: -1px;
    top: -1px;
    width: 1px;
    height: 1px;
    visibility: hidden;
    overflow: hidden;
  }
  .rich-text a {
    color: #00999b;
    text-decoration: none;
  }
  .rich-text h2 {
    font-size: 32px;
  }
  .rich-text h3 {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
    font-weight: 600 !important;
    font-size: 16px;
    padding-top: 0.5rem !important;
  }

  .rich-text h5 {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
    font-weight: 600 !important;
    font-size: 20px;
    padding-top: 0.5rem !important;
  }

  .rich-text {
    ul {
      padding-left: 0;
      list-style: none;
      margin-top: 0;
      margin-bottom: 1rem;
      li {
        position: relative;
        padding-left: 2em;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
  
        &::before {
          content: "";
          top: 0;
          left: 0;
          position: absolute;
          width: 1.5em;
          height: 1.5em;
          background: #00999b
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.513' height='7.252' viewBox='0 0 9.513 7.252'%3E%3Cpath id='Path_79' data-name='Path 79' d='M8.388,68.122a.426.426,0,0,0-.6,0l-5.1,5.1-1.96-1.96a.426.426,0,0,0-.6.6l2.261,2.261a.426.426,0,0,0,.6,0l5.4-5.4A.426.426,0,0,0,8.388,68.122Z' transform='translate(0.5 -67.497)' fill='%23fff' stroke='%23fff' stroke-width='1'/%3E%3C/svg%3E%0A")
            center / 0.6em no-repeat;
          border-radius: 50%;
        }
      }
    }
  }
  
  .rich-text table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: var(--bs-border-color);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    // margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    border-color: var(--bs-table-border-color);
  }
  .rich-text table > thead {
    vertical-align: bottom;
  }
  .rich-text table > tbody {
    vertical-align: inherit;
  }
  .rich-text table > :not(caption) > * > * {
    // padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }


  .margin-right {
    border: solid black 1px;
  }

  // table responsive



  // .responsive-table {
  //   width: 100%;
  //   border-collapse: collapse;
  // }
  
  // .responsive-table thead {
  //   display: none;
  // }
  
  // .responsive-table td,
  // .responsive-table th {
  //   display: block;
  //   padding: .5em;
  //   text-align: left;
  // }
  
  // .responsive-table td:before {
  //   content: attr(data-label);
  //   font-weight: bold;
  // }
  
  // @media (min-width: 40em) {
  //   .responsive-table thead {
  //     display: table-header-group;
  //   }
  
  //   .responsive-table td,
  //   .responsive-table th {
  //     display: table-cell;
  //     padding: .5em;
  //   }
    
  //   .responsive-table td:before {
  //     content: none;
  //   }
  // }
  
  
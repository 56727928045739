

.filter-list li li {
    position: relative;
    padding-left: 1.5rem;
}
.filter-list li li .category-collapse .form-check:last-child {
    margin-bottom: .5rem;
}

.category-toggle {
    color: $primary;
    &::before {
        content: "";
        width: .5rem;
        height: .5rem;
        transition: transform .15s;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.486 5.657'%3E%3Cpath data-name='Path 74' d='m4.243 5.657 4.243-4.243L7.071 0 4.243 2.829 1.415 0 0 1.414Z' fill='%23008a8c'/%3E%3C/svg%3E") center left / .5rem no-repeat;
        margin: auto 0;
    }

    &.collapsed {
        color: $body-color;
        &::before {
        transform: rotate(-90deg);
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.486 5.657'%3E%3Cpath data-name='Path 74' d='m4.243 5.657 4.243-4.243L7.071 0 4.243 2.829 1.415 0 0 1.414Z' fill='%23000'/%3E%3C/svg%3E") center left / .5rem no-repeat;
    }
    }
}

input[data-bs-toggle="collapse"] {
    +label::before {
        content: "";
        width: .5rem;
        height: .5rem;
        background-color: red;
        display: block;
        position: absolute;
        left: 10px;
        top: 7px;
        transition: transform .15s;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.486 5.657'%3E%3Cpath data-name='Path 74' d='m4.243 5.657 4.243-4.243L7.071 0 4.243 2.829 1.415 0 0 1.414Z' fill='%23008a8c'/%3E%3C/svg%3E") center left / .5rem no-repeat;
    }

    &.collapsed+label::before {
        transform: rotate(-90deg);
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.486 5.657'%3E%3Cpath data-name='Path 74' d='m4.243 5.657 4.243-4.243L7.071 0 4.243 2.829 1.415 0 0 1.414Z' fill='%23000'/%3E%3C/svg%3E") center left / .5rem no-repeat;
    }
}
.float-details {
    float: right;
    margin-top: -992px;
}

.greencircle-buttonabsolute{
    
        right: -2rem;
        top: 1rem;
        border-radius: 50%;
        background: $primary;
        height: 44px;
        width: 44px;
      }

.rhomb {
    position: relative;
    display: flex;

    &::before {
        content: "";
        padding-top: 100%;
        display: block;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -8px;
        right: -8px;
        height: 65%;
        width: 65%;
        background-image: radial-gradient($white 3px, transparent 0);
        background-size: 24px 24px;
        z-index: -0;
        opacity: .1;
    }

    &-grid {
        border: .375rem solid transparent;

        > .rhomb-fig {
            height: 50%;
            width: 50%;
        }

        >.rhomb-fig:first-child {
            left: 50%;
            top: 0;
            margin-top: -.375rem;
            transform: translateX(-50%);
        }
        >.rhomb-fig:nth-child(2) {
            top: 50%;
            left: 0;
            margin-left: -.375rem;
            transform: translateY(-50%);
        }
        >.rhomb-fig:nth-child(3) {
            top: 50%;
            right: 0;
            margin-right: -.375rem;
            transform: translateY(-50%);
        }
        >.rhomb-fig:last-child {
            bottom: 0;
            left: 50%;
            margin-bottom: -.375rem;
            transform: translateX(-50%);
        }
    }

    &-fig {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        margin-bottom: 0;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        z-index: 1;

        &::before {
            content: "";
            left: 0;
            top: 0;
            position: absolute;
            border: 3px solid rgba($color: $white, $alpha: .8);
            width: 100%;
            height: 100%;
            transform: scale(.71) rotate(45deg);
            z-index: 1;
        }

        > img {
            @include img-fluid;
            transition: transform .25s ease-in-out;
        }
    }

    &-fig:hover > img {
        transform: scale(1.1);
    }


}
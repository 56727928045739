// transparent background and border properties included for button version.
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

.btn-close {
  box-sizing: content-box;
  // width: $btn-close-width;
  // height: $btn-close-height;
  padding: $btn-close-padding-y $btn-close-padding-x;
  color: $btn-close-color;
  border: 0; // for button elements
  line-height: 1;
  background-color: transparent;

  @include border-radius();
  opacity: $btn-close-opacity;

  &-icon {
    font-size: 1.5rem;
    vertical-align: -.125em;
  }


  // Override <a>'s hover style
  &:hover {
    color: $primary;
    text-decoration: none;
    opacity: $btn-close-hover-opacity;
  }

  &:focus {
    outline: 0;
    box-shadow: $btn-close-focus-shadow;
    opacity: $btn-close-focus-opacity;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: $btn-close-disabled-opacity;
  }
}

.btn-close-white {
  filter: $btn-close-white-filter;
}

.svg-symbols, .svg-logo {
    position: absolute;
    z-index: -1;
    left: -1px;
    top: -1px;
    width: 1px;
    height: 1px;
    visibility: hidden;
    overflow: hidden;
}

.icon {
    display: inline-block;
    fill: currentColor;
    vertical-align: middle;
    line-height: 1em;
    height: 1em;
    width: 1em;
    &-sm {
        font-size: 1.25rem;
    }
    &-md {
        font-size: 1.5rem;
    }
}

.svg-symbols {
    position: absolute;
    z-index: -1;
    left: -1px;
    top: -1px;
    width: 1px;
    height: 1px;
    visibility: hidden;
    overflow: hidden;
}

.icon-svg {
    display: inline-block;
    fill: currentColor;
    vertical-align: middle;
    line-height: 1em;
    height: 1em;
    width: 1em;
}


.icon-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.icon {
    &-md {
        &.icon-circle {
            width: 2.5em;
            height: 2.5em;
        }

        >.icon-svg {
            font-size: 1.5rem;
        }
    }
    &.lg {
        &.icon-circle {
            width: 3.5em;
            height: 3.5em;
        }
        
          font-size: 2rem;
        
    }
    &-xl {
        &.icon-circle {
            width: 4.5em;
            height: 4.5em;
        }

        >.icon-svg {
            font-size: 3rem;
        }
    }
}


.icon-inline {
    vertical-align: -.125em;
}



.icon-switch {
    width: 1em;
    height: 1em;
    font-size: 1em;
    position: relative;
    overflow: hidden;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    will-change: transform;

    > .icon-svg {
        flex-shrink: 0;
    }

    >.icon-svg:first-child {
        opacity: 1;
    }

    >.icon-svg:last-child {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
}

.collapsed .icon-switch {
    >.icon-svg:first-child {
        transform: scale(1);
    }
    >.icon-svg:last-child {
        transform: scale(.6);
    }
}
[aria-expanded="true"]:not(.collapsed) .icon-switch {
    >.icon-svg:first-child {
        opacity: 0;
        transform: scale(.6);
    }
    >.icon-svg:last-child {
        opacity: 1;
        transform: scale(1);    
    }
}

.icon-switch-scale > .icon-svg {
    transition: opacity 0s .1s,transform .2s;
}
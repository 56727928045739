.main-footer {

    .list-link {
        color: $body-color;
        text-decoration: none;
        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }

}
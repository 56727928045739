.banner {
  display: flex;

  
  @include media-breakpoint-up(lg) {
    min-height: 500px;
  }
}

.banner-fig {
  margin-bottom: 0;
}

.banner-img {
  padding-bottom: 70px;
  padding-left: 70px;
  max-height: 480px;
}


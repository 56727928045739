.inline-both {
  position: fixed;
  bottom: 46rem;
  right: 2rem;
}

// @media screen and (max-width: 480px) {
//     .inline-both {
//         margin-top: 13px ;
//     }
//   }

//

.next-relative {
  position: relative;
}

.next-absolute {
  position: absolute;
  bottom: 1rem;
  left: 7rem;
  z-index: 1;
  transform: rotate(180deg);
  opacity: 0.2;

  :hover {
    background-color: white;
    border-radius: 50%;
    color: black;
  }
}

.prev-relative {
  position: relative;
}

.prev-absolute {
  position: absolute;
  bottom: 2rem;
  left: 5rem;
  z-index: 1;
  opacity: 0.3;

  :hover {
    background-color: white;
    border-radius: 50%;
    color: black;
  }
}

.pagination-relative {
  position: relative;
  display: inline-block;
}

.pagination-absolute {
  position: absolute;
  bottom: 1rem;
  left: 10rem;
  z-index: 1;
  display: inline;
}

.swiper-pagination {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.swiper-pagination-bullet {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

.swiper-pagination-bullet-active {
  background: linear-gradient(110deg, #1e1b58 0%, #00999b 59%, #d91a5b 100%);
}

.swiper-pagination-horizontal {
  display: flex;
  width: 90px;
}

.home-height {
  min-height: 684px !important;
  max-height: 42rem;
}

.home-cards {
  .avatar-border {
    border: 2px solid transparent;
    font-size: 1.5rem;
  }

  &:hover {
    .avatar-border {
      border: 2px solid $black;
      cursor: pointer;
      transition: 0.25s ease-in-out;
    }
    .title-underline {
      text-decoration: underline !important;
      cursor: pointer;
      transition: 0.25s ease-in-out;
    }
  }
}


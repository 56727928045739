.article-header {
    @include media-breakpoint-up(lg) {
        min-height: 30vw;
    }
}
.article-cover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    margin-bottom: 0;
    overflow: hidden;
    z-index: -1;

    &::after {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba($color: $black, $alpha: .5);
    }

    > img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
}
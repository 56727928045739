.contact-cover {
    position: relative;
    .cover-fig {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        margin-bottom: 0;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            background-color: rgba($color: $pink, $alpha: .1);
        }

        > img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;

        }
    }
}
.news-resources-cards {
  border: 3px solid transparent;

  .border-picture {
    border: solid 3px transparent;
  }

  &:hover {
    .border-picture {
      border: solid 3px #d91a5b;
      cursor: pointer;
      transition: 0.25s ease-in-out;
    }
    .title-underline {
      text-decoration: underline !important;
      cursor: pointer;
      transition: 0.25s ease-in-out;
    }
  }
}

.nav-color {
  &-bottom {
    border-bottom: solid 3px transparent;
    &:hover {
      color: #d91a5b !important;
      border-bottom: solid 3px;
      border-color: #d91a5b !important;
      transition: 0.25s ease-in-out;
    }
  }
}

@media screen and (max-width: 480px) {
  .media-width {
    width: auto !important;
  }
}

.baner-cards-media {
  max-height: 19rem !important;
  min-height: 19rem !important;
}

.image-position {
  margin-left: -1rem;
  height: 29rem;
  margin-top: 1rem;
  width: -webkit-fill-available;
}

@media screen and (max-width: 480px) {
  .image-position {
    // margin-left: 0;
    height: 20rem;
    width: 23rem;

  }
}

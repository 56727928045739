.whitecircle-buttonabsolute {
  right: -2rem;
  top: 1rem;
  border-radius: 50%;
  background: white;
  height: 44px;
  width: 44px;
}

.whitecircle-1absolute {
  right: -2rem;
  top: 1rem;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: white;
}

.text-limit-members {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.red-cards {
  &-border {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid red;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 480px) {
  .media-cd {
    flex-direction: column;
  }
}

.width-hr {
  width: 53rem;
}

@media screen and (max-width: 1399px) {
  .height-responsive {
    height: 86px;
  }
}

.radius {
  height: 9rem;
  width: 9rem;
}
.hover-cards {
  .avatar-border {
    border: 3px solid transparent;
    // font-size: 2.2rem;
  }
  .border-color {
    border: 3px solid transparent;
  }

  &:hover {
    .avatar-border {
      border: 3px solid $primary;
      cursor: pointer;
      transition: 0.25s ease-in-out;
    }
    .border-color {
      border: 3px solid $primary;
      cursor: pointer;
      transition: 0.25s ease-in-out;
    }
  }
}

.checkbox-menu li label {
  display: block;
  padding: 3px 10px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  margin: 0;
  transition: background-color 0.4s ease;
}
.checkbox-menu li input {
  margin: 0px 5px;
  top: 2px;
  position: relative;
}

.checkbox-menu li.active label {
  background-color: #cbcbff;
  font-weight: bold;
}

.checkbox-menu li label:hover,
.checkbox-menu li label:focus {
  background-color: #f5f5f5;
}

.checkbox-menu li.active label:hover,
.checkbox-menu li.active label:focus {
  background-color: #b8b8ff;
}

.button-dropdown {
  border: solid 1px lightgray !important;
  margin-top: 2rem;
  padding: 12px;
  width: 20rem;
}

// css after
.svg-position {
  position: absolute;
  top: 40px;
  right: 18px;
  z-index: 33;
  // pointer-events: none;
  cursor: auto;
}

.svg-resize {
  font-size: 11px;
  color: #595e63;
}

.form-label {
  font-size: 14px;
}

.my-select option {
  color: green !important;
}

.multi-dropdown-menu {
  // padding: 10px;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
  position: absolute;
  max-width: 481px;
  border: 1px solid gray;
}
label {
  display: flex;
  align-items: center;
  // margin-bottom: 5px;
}
input[type="checkbox"]:checked + span {
  background-color: rgba(30,144,255,255);
  color: white;
}
// input[type="checkbox"]:checked {
//   display: none;
// }

.dropdown-multi-menu .multi-dropdown-menu {
  display: none;
}

.multi-dropdown-menu .option-one-hover:hover {
  background-color: rgba(30,144,255,255);
}
.multi-dropdown-menu .option-two-hover:hover {
  background-color: rgba(30,144,255,255);
}
.multi-dropdown-menu .option-three-hover:hover {
  background-color: rgba(30,144,255,255);
}
.multi-dropdown-menu .option-four-hover:hover {
  background-color: rgba(30,144,255,255);
}
.multi-dropdown-menu .option-five-hover:hover {
  background-color: rgba(30,144,255,255);
}

.sitemap {
  li {
    margin: 0.75rem 0;
  }
  > ul {
    list-style: none;
    padding-left: 0;
  }
  // first level
  &-list > li {
    margin-bottom: 2rem;
  }
  &-list > li > a {
    @include font-size(1.5rem);
    font-weight: $font-weight-bold;
  }
  &-list > li > a + ul {
    border-top: 1px solid $gray-200;
    padding-top: 0.5rem;
    margin-top: 0.25rem;
  }
  // second level
  &-list > li > ul > li > a {
    @include font-size(1.25rem);
  }
  // third level
  &-list > li > ul > li > ul {
    margin-bottom: 1.5rem;
  }
  &-list > li > ul > li > ul > li > a {
    @include font-size(1.125rem);
  }

  a {
    color: $primary;
    text-decoration: none;
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}

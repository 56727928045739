.arrow-right::after {
  content: "";
  background-image: url("../images/arrow-right.svg");
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid black;
  position: absolute;
  right: -1.25rem;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}

.arrow-top::after {
  content: "";
  background-image: url("../images/arrow-right.svg");
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%) rotate(270deg);
  border: 1px solid black;
  position: absolute;
  top: -1.25rem;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}

.no-bottom-border {
  border-bottom-style: none;
}

.nobottom {
  border-bottom-style: hidden;
  border-top-style: hidden;
}

.nobottomlast {
  border-top-style: hidden;
}


.progression-pathway {
  &-border {
   
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}
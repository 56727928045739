.textarearelative {
  position: relative;
}

.textareaabsolute {
  position: absolute;
  top: 22px;
  right: 10px;
  background-color: #d6efef;
  padding: 12px;
  border-radius: 13px 13px 13px 13px;
}

.text-limit {
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .media-margin {
    margin-top: -1.5rem !important;
  }
}

@media screen and (max-width: 480px) {
  .media-comments {
    width: 75% !important;
  }
}

.dropdown-extension {
  line-height: 36px;
  height: auto;
  width: 44rem;
  background-color: white !important;
  margin-left: 10rem !important;
}

@media only screen and (min-width: 340px) and (max-width: 991px)  {
  .dropdown-extension {
    line-height: 36px;
    height: auto;
    width: 20rem;
    margin-left: 0 !important;
    text-align: center !important;
  }
}

.vertical {
  transform: rotate(90deg) scaleX(-1);
  margin: 0;
}

.badge {
  border-radius: 50%;
  position: absolute;
  right: -5.8%;
  top: -0.8rem;
  color: white;
  width: 1.3rem;
  height: 1.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#lblCartCount {
  font-size: 12px;
  background: #d91a5b;
  color: #fff;
  //   vertical-align: top;
  //   margin-left: -10px;
}

.scroll-effect {
  overflow-y: auto;
}

@media only screen and (min-width: 991px) {
  .disappear-large {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .disappear {
    display: none;
  }
}

.main-header {
    top: 0;
    position: sticky;
    box-shadow: $box-shadow-sm;
    z-index: $zindex-sticky;
    background-color: $white;
}

// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
    position: relative;
    display: flex;
    // flex-wrap: wrap; // allow us to do the line break for collapsing content
    flex-wrap: initial;
    align-items: center;
    justify-content: space-between; // space out brand from logo
    padding-right: $navbar-padding-x; // default: null
    padding-left: $navbar-padding-x; // default: null

    >.container {
        display: flex;
        flex-wrap: inherit;
        align-items: center;
        justify-content: space-between;
    }
}


.navbar-open {

    .navbar-primary .action-item-search,
    .navbar-primary .action-item-signin {
        opacity: 0.5;
        pointer-events: none;
    }

    .navbar-secondary .nav-item {
        animation-name: navitemSlideUp;
        animation-duration: 0.15s;
        animation-fill-mode: both;

        @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
                animation-delay: #{0.04 * $i}s;
            }
        }
    }
}

// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
    padding-top: $navbar-brand-padding-y;
    padding-bottom: $navbar-brand-padding-y;
    margin-right: $navbar-brand-margin-end;
    @include font-size($navbar-brand-font-size);
    text-decoration: if($link-decoration==none, null, none);
    white-space: nowrap;
    position: relative;
    z-index: 4;

    &:hover,
    &:focus {
        text-decoration: if($link-hover-decoration==underline, none, null);
    }

    .brand-logo {
        width: auto;
        max-height: 64px;

        @include media-breakpoint-up(lg) {
            max-height: 72px;
        }
    }
}


// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
    display: flex;
    flex-direction: row; // cannot use `inherit` to get the `.navbar`s value
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .nav-link,
    .nav-btn {
        height: 3rem;
        display: inline-flex;
        align-items: center;
        padding: .5rem .75rem;
        border: 1px solid $indigo-200;
        border-radius: $border-radius;
        background-color: transparent;
        @include media-breakpoint-up(lg) {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &:hover {
            border-color: $indigo;
        }
    }

    .nav-btn {
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    }


    .btn-toggler-menu {
        color: $white;
        border-color: $primary !important;
        background-color: $primary;
        &:hover,
        :focus {
            background-color: shade-color($primary, $btn-hover-bg-shade-amount);
        }
    }

        .dropdown-toggle-profile {
            border: none;
            background-color: transparent;
            padding: 0;
            display: inline-flex;
            align-items: center;
    
            &:hover .avatar-initials,
            :focus .avatar-initials {
                border-color: shade-color($primary, $btn-hover-bg-shade-amount);
                background-color: shade-color($primary, $btn-hover-bg-shade-amount);
            }
    
            &::after {
                border-top-color: $indigo-300;
            }
        }

}

// Navbar search 
.navbar-search {
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 100%;
    transition: .2s ease;
    overflow-y: auto;

    @include media-breakpoint-up(lg) {
        top: 104px;
    }

    &::after {
        content: "";
        top: 80px;
        width: 100%;
        height: 100%;
        position: fixed;
        bottom: 0;
        background: transparent $bg-gradient-mesh 0% 0% no-repeat padding-box;       
        z-index: -1;
        opacity: 0;
        transition: opacity .2s ease;
        @include media-breakpoint-up(lg) {
            top: 104px;
        }
    }

    .search-dialog {
        opacity: 0;
        transform: translateY(2rem);
    }

    .input-group-search {
        .form-control {
            color: $gray-300;
            background-color: transparent;
            border-color: transparent;
            border-radius: 0;

            &::placeholder {
                color: $gray-400;
            }

            &:focus {
                color: $white;
                box-shadow: none;
            }

            &:not(:placeholder-shown)+.btn-search {
                visibility: visible;
            }

            &-underline {
                display: block;
                width: 100%;
                height: 1px;
                background-color: $gray-300;
                transform: scale3d(0, 1, 1);
                transform-origin: left center;
                animation-delay: 0.1s;
                visibility: hidden;
            }
        }

        .btn-search {
            visibility: hidden;
            border-radius: $btn-border-radius !important;
        }
    }

    .search-suggest-list {
        li {
            color: $white;
            padding: .25rem 0;

            >span {
                padding-left: .5rem;
            }
        }

        a {
            text-decoration: none;

            &:focus {
                text-decoration: underline;
            }
        }
    }
}

.navbar-search.show {
    .input-group-search {
        .form-control {
            &-underline {
                animation-name: fade-line;
                animation-duration: 0.7s;
                animation-fill-mode: both;
                visibility: visible;
            }
        }
    }
}

.has-navbar-search {
    overflow-y: hidden;

    .search-dialog {
        animation-name: fade-up;
        animation-duration: 0.5s;
        animation-fill-mode: both;
        visibility: visible;
    }

    .navbar-search::after {
        opacity: 1;
    }
}

.has-navbar-menu .main-header,
.has-navbar-search .main-header {
    @media (hover: hover) {
    padding-right: 17px;
    }
}


// navbar menu
.navbar-menu {
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100% !important;
    max-height: calc(100vh - 80px);
    transition: opacity .2s ease;
    overflow-y: auto;
    display: flex;

    >.container {
        display: flex;
        flex-direction: column;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    &::before {
        content: "";
        position: fixed;
        top: 80px;
        left: 0;
        right: 0;
        display: block;
        height: 1px;
        background-color: $primary;
        z-index: 1;
        @include media-breakpoint-up(lg) {
            top: 104px;
        }
    }

    &::after {
        content: "";
        top: 80px;
        width: 100%;
        height: 100%;
        position: fixed;
        background: transparent $bg-gradient-mesh 0% 0% no-repeat padding-box;       
        z-index: -1;
        opacity: 0;
        bottom: 0;
        transition: opacity .2s ease;
    }

    @include media-breakpoint-up(lg) {
        top: 104px;
        max-height: calc(100vh - 104px);

        &::after {
            top: 104px;
        }
    }
}

// navbar primary 
.navbar-primary-nav {
    @include list-unstyled;
    // display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    opacity: 0;
    position: relative;
    transform: translateY(2rem);

    @include media-breakpoint-down(lg) {
        flex-grow: 1;
    }

    .nav-item {
            margin-bottom: .5rem;
    }

    .dropdown-menu
    .nav-item:hover {
        .nav-link {
            color: $primary;
        }

        .dropdown-toggle {
            opacity: 1;
            @include media-breakpoint-down(lg) {
                border-color: $white;
            }
        }
    }

    .nav-link {
        color: $navbar-dark-color;
        font-size: 1.125rem;

        @include media-breakpoint-down(lg) {
            margin-right: 3.5rem;
            min-height: 2.5rem;
            padding-top: .375rem;
            padding-bottom: .375rem;
        }

        &:hover {
            color: $navbar-dark-hover-color;
        }
    }

    .dropdown-toggle {
        font-size: .875rem;
        color: $navbar-dark-color;
        border: 1px solid transparent;
        background-color: transparent;
        padding: 0;
        opacity: .6;
    

        &::after {
            display: none;
        }

        @include media-breakpoint-down(lg) {
            top: 0;
            right: 2px;
            position: absolute;
            height: 2.5rem;
            width: 2.5rem;
            transform: rotate(90deg);
            border-color: rgba($color: $white, $alpha: .5);
            border-radius: $border-radius;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: opacity .3s, background-color .3s, border-color .3s;

            &.show {
                transform: rotate(-90deg);
                opacity: 1;
                color: $indigo;
                background-color: $white;
            }

        }

    }

    .dropdown-menu {
        padding: 1rem 0;

        @include media-breakpoint-down(lg) {
            position: static;
        }
    }

    .dropdown-link {
        color: $indigo;
        text-decoration: none;
        white-space: unset;

        &:hover {
            color: $primary;
            text-decoration: underline;
            background-color: transparent;
        }
    }

    // desktop view
    @include media-breakpoint-up(lg) {
        min-height: 480px;
        margin-top: auto;
        margin-bottom: auto;
        // justify-content: center;

        .nav-item {
            padding-top: .25rem;
            padding-bottom: .25rem;
            padding-right: 2rem;
            max-width: 40%;
            position: relative;
        }

        // .nav-item:hover {
        //     .dropdown-toggle {
        //         // pointer-events: none;
        //     }
        // }

        .nav-link {
            display: inline;
            font-size: 1.5rem;
            vertical-align: middle;
        }

        .dropdown-toggle {
            margin-left: 1rem;
            margin-right: -2rem;
            position: relative;
            top: 2px;
        }


        .dropdown-menu {
            padding: 1rem .5rem;
            left: 64% !important;
            top: 0 !important;
            // height: 100%;
            
          
            @include media-breakpoint-up(xl) {
                padding: 1.5rem;
            }
        }

        // .dropdown:hover .dropdown-menu {
        //     // display: block !important;
        //     // opacity: 1;
        //     // animation-name: fade-right-dropdown-menu;
        //     // animation-duration: .3s;
        //     // animation-fill-mode: both;
        // }
    }
}

// navbar secondary 
.navbar-secondary-nav {
    @include list-unstyled;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .nav-item {
        padding: .25rem 1rem .25rem 0;
    }
    // desktop view
    @include media-breakpoint-up(md) {
        flex-direction: row;

        .nav-item {
            padding-right: 1.5rem;
        }
    }

}

// navbar footer 
.navbar-footer {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    display: flex;
    opacity: 0;
    justify-content: space-between;
    transform: translateY(2rem);

    &::before {
        content: "";
        top: 0;
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $gray-300;
        transform: scale3d(0, 1, 1);
        transform-origin: left center;
        animation-delay: 0.1s;
        visibility: hidden;
    }

}

.navbar-menu.show {
    .navbar-footer::before {
        animation-name: fade-line;
        animation-duration: 0.7s;
        animation-fill-mode: both;
        visibility: visible
    }
}

.has-navbar-menu {
    overflow-y: hidden;

    .navbar-menu::after {
        opacity: 1;
    }

    .navbar-primary-nav,
    .navbar-footer {
        animation-name: fade-up;
        animation-duration: 0.5s;
        animation-fill-mode: both;
        visibility: visible;
    }

    .navbar-footer {
        animation-delay: .15s;
    }

}

// Styles for switching between navbars with light or dark background.

// Dark links against a light background
.navbar-dark {


    .navbar-secondary-nav {
        .nav-link {
            color: $navbar-dark-color;

            &:hover,
            &:focus {
                color: $navbar-dark-hover-color;
            }

            &.disabled {
                color: $navbar-dark-disabled-color;
            }
        }

        .show>.nav-link,
        .nav-link.active {
            color: $navbar-dark-active-color;
        }

    }
 
}


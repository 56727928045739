.cirlce-lg {
    padding: 0;
    border-radius: 50%;
    width: 6.2rem;
    height: 6.2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    ;
  }

  .checkmark {
    display: inline-block;
    width: 39px;
    height: 22px;
    border-radius: 50%;
    /* background-color: #2ecc71; */
    color: white;
    font-size: 41px;
    text-align: center;
    line-height: 18px;
  }
.banner-background {
  background: transparent
    linear-gradient(110deg, #1e1b58 0%, #00999b 59%, #d91a5b 100%) 0% 0%;
}

.bg-color-bom {
  background: #00999b29;
}

.statistics-ju {
  text-align: left;
  letter-spacing: 0px;
  color: #1e1b58;
  opacity: 1;
}

.statistics-numbers-ju {
  text-align: left;
  letter-spacing: 0px;
  color: #d91a5b;
  opacity: 1;
}

.absolute-svg-correct {
  top: 150px;
  left: 16px;
  background-color: #00999b;
  color: #1e1b58;
  text-transform: uppercase;
  opacity: 1;
  padding: 8px;
  border-radius: 50%;
  height: 27px;
  width: 27px;
  border: 1px solid #ffffff;
}

.cards-section-book {
  position: relative;
}

.cards-section-bom-1 {
  position: relative;
}

.absolute-section-bom-1 {
  width: 14rem;
  background-color: #00999b1a;
  padding: 10px 15px 8px 12px;
  color: #00999b;
  border-radius: 8px;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.absolute-section-bom-2 {
  width: 15rem;
  background-color: #1e1b581a;
  padding: 10px 15px 8px 12px;
  color: #1e1b58;
  border-radius: 8px;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.absolute-section-bom-3 {
  width: 16rem;
  background-color: #0099ff1a;
  padding: 10px 15px 8px 12px;
  color: #0099ff;
  border-radius: 8px;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.absolute-section-bom-4 {
  width: 14rem;
  background-color: #f2833b1a;
  padding: 10px 15px 8px 12px;
  color: #f2833b;
  border-radius: 8px;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.absolute-section-bom-5 {
  width: 14rem;
  background-color: #d91a5b1a;
  padding: 10px 15px 8px 12px;
  color: #d91a5b;
  border-radius: 8px;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.absolute-section-bom-6 {
  width: 17rem;
  background-color: #9a05c71a;
  padding: 10px 15px 8px 12px;
  color: #9a05c7;
  border-radius: 8px;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.absolute-section-bom-7 {
  width: 14rem;
  background-color: #009b611a;
  padding: 10px 15px 8px 12px;
  color: #009b61;
  border-radius: 8px;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.bom-year {
  color: #666666;
}

.absolute-section {
  position: absolute;
  top: -20px;
  left: 16px;
  background-color: #f7d0dd;
  padding: 10px 15px 8px 12px;
  color: #d91a5b;
  border-radius: 1rem;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.absolute-section2 {
  position: absolute;
  top: -20px;
  left: 16px;
  background-color: #d1d0dc;
  padding: 10px 15px 8px 12px;
  color: #1e1b58;
  border-radius: 1rem;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.absolute-section3 {
  position: absolute;
  top: -20px;
  left: 16px;
  background-color: #fae3d4;
  padding: 10px 15px 8px 12px;
  color: #f2833b;
  border-radius: 1rem;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.cards-section-bacs {
  position: relative;
}

.absolute-section-bacs {
  position: absolute;
  top: -38px;
  left: 20px;
  height: 75px;
  width: 75px;
  background-color: #000000;
  padding: 21px 18px;
  border-radius: 3rem;
}

.cards-section-cc {
  position: relative;
}

.absolute-section-cc {
  position: absolute;
  top: -38px;
  left: 20px;
  height: 75px;
  width: 75px;
  background-color: #000000;
  padding: 23px 21px;
  border-radius: 3rem;
}

.absolute-section-ib {
  position: absolute;
  top: -38px;
  left: 20px;
}

.cards-section-dd {
  position: relative;
}

.absolute-section-dd {
  position: absolute;
  top: -38px;
  left: 20px;
  height: 75px;
  width: 75px;
  background-color: #000000;
  padding: 23px 20px;
  border-radius: 3rem;
}

.carrer-development-bg {
  background: transparent
    linear-gradient(115deg, #1e1b58 0%, #00999b 67%, #d91a5b 100%) 0% 0%
    no-repeat padding-box;
}

@media (max-width: 991px) {
  .basc-card {
    padding-top: 3rem;
  }
}

@media (max-width: 500px) {
  .bom-year {
    padding-top: 4px !important;
  }
}


.btn-primary-0:hover {
  color: white;
  background-color: #00999B;
  // scroll-behavior: smooth;

}

.downtoup{

  width: 336px;
  height: 62px;
  box-sizing: border-box;
  text-align: center;
  background: linear-gradient(transparent, transparent 50%, #00999B 50%, #00999B);
  background-size: 300% 200%;
  border-radius: 1rem;
 
  /*trasition effect for background*/
  transition: background 0.5s;

}

.downtoup:hover {
  background-position: 100% 100%;
}


.list-check {
    @include list-unstyled;
    li {
        position: relative;
        padding-left: 2em;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        &::before {
            content: "";
            top: 0;
            left: 0;
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            background: $primary url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.513' height='7.252' viewBox='0 0 9.513 7.252'%3E%3Cpath id='Path_79' data-name='Path 79' d='M8.388,68.122a.426.426,0,0,0-.6,0l-5.1,5.1-1.96-1.96a.426.426,0,0,0-.6.6l2.261,2.261a.426.426,0,0,0,.6,0l5.4-5.4A.426.426,0,0,0,8.388,68.122Z' transform='translate(0.5 -67.497)' fill='%23fff' stroke='%23fff' stroke-width='1'/%3E%3C/svg%3E%0A") center / .6em no-repeat;
            border-radius: 50%;
        }
    }
}


.list-check-red {
    @include list-unstyled;
    li {
        position: relative;
        padding-left: 2em;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        &::before {
            content: "";
            top: 0;
            left: 0;
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            background: red url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.513' height='7.252' viewBox='0 0 9.513 7.252'%3E%3Cpath id='Path_79' data-name='Path 79' d='M8.388,68.122a.426.426,0,0,0-.6,0l-5.1,5.1-1.96-1.96a.426.426,0,0,0-.6.6l2.261,2.261a.426.426,0,0,0,.6,0l5.4-5.4A.426.426,0,0,0,8.388,68.122Z' transform='translate(0.5 -67.497)' fill='%23fff' stroke='%23fff' stroke-width='1'/%3E%3C/svg%3E%0A") center / .6em no-repeat;
            border-radius: 50%;
        }
    }
}



.list-check-indigo {
    @include list-unstyled;
    li {
        position: relative;
        padding-left: 2em;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        &::before {
            content: "";
            top: 0;
            left: 0;
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            background: indigo url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.513' height='7.252' viewBox='0 0 9.513 7.252'%3E%3Cpath id='Path_79' data-name='Path 79' d='M8.388,68.122a.426.426,0,0,0-.6,0l-5.1,5.1-1.96-1.96a.426.426,0,0,0-.6.6l2.261,2.261a.426.426,0,0,0,.6,0l5.4-5.4A.426.426,0,0,0,8.388,68.122Z' transform='translate(0.5 -67.497)' fill='%23fff' stroke='%23fff' stroke-width='1'/%3E%3C/svg%3E%0A") center / .6em no-repeat;
            border-radius: 50%;
        }
    }
}


.list-check-orange {
    @include list-unstyled;
    li {
        position: relative;
        padding-left: 2em;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        &::before {
            content: "";
            top: 0;
            left: 0;
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            background: orange url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.513' height='7.252' viewBox='0 0 9.513 7.252'%3E%3Cpath id='Path_79' data-name='Path 79' d='M8.388,68.122a.426.426,0,0,0-.6,0l-5.1,5.1-1.96-1.96a.426.426,0,0,0-.6.6l2.261,2.261a.426.426,0,0,0,.6,0l5.4-5.4A.426.426,0,0,0,8.388,68.122Z' transform='translate(0.5 -67.497)' fill='%23fff' stroke='%23fff' stroke-width='1'/%3E%3C/svg%3E%0A") center / .6em no-repeat;
            border-radius: 50%;
        }
    }
}

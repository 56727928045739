@each $color-key, $color-value in $grays {
    .text-gray-#{$color-key} {
        color: #{$color-value} !important;
    }
    .border-gray-#{$color-key} {
        border-color: #{$color-value} !important;
    }
    .bg-gray-#{$color-key} {
        background-color: #{$color-value} !important;
    }  
}

@each $color-key, $color-value in $colors {
    .text-#{$color-key} {
        color: #{$color-value} !important;
    }
    .border-#{$color-key} {
        border-color: #{$color-value} !important;
    }
    .bg-#{$color-key} {
        background-color: #{$color-value} !important;
    }  
}

@each $z-index-key, $z-index-value in $z-index {
    .z-index-#{$z-index-value} {
        z-index: #{$z-index-value} !important;
    }
}


.bg-primary-100 {
    background-color: $teal-100 !important;
}
.bg-pink-100 {
    background-color: $pink-100;
}
.bg-indigo-100 {
    background-color: $indigo-100 !important;
}
.bg-orange-100 {
    background-color: $orange-100 !important;
}

.bg-teal-100 {
    background-color: $teal-100;
}

.bg-blue-100 {
    background-color: $blue-100;
}

.bg-red-100 {
    background-color: $red-100 !important;
}

.bg-purple-100 {
    background-color: $purple-100 !important; 
}

.bg-green-100 {
    background-color: $green-100 !important;
}


.fs-small {
    font-size: $small-font-size !important;
}

.bg-gradient-mesh {
    background-image: $bg-gradient-mesh;       
}


.link-hover-underline {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
.form-check-lg {
    
    > .form-check-input {
          font-size: 1.5rem;
          margin-top: 0;
          margin-left: -1em;
    }

    label {
        margin-left: 0.5rem;
    }

}


.input-group-password {
    > .form-control {
        padding-right: 3.5rem !important;
        border-top-right-radius: $input-border-radius !important;
        border-bottom-right-radius: $input-border-radius !important;
    }
}
.input-group-password-toggle {
    padding: 0 1rem;
    width: 3.5rem;
    margin-left: -3.5rem !important;
    display: flex;
    align-items: center;
    z-index: 5;
    border: none;
    margin-right: 1px;
    background-color: transparent;
    font-size: 1.5rem;
    opacity: 0.5;
    transition: opacity .15s;

    &:hover {
        color: $primary;
        opacity: 1;
    }
}


.input-group-quantity {
    .btn {
        padding-left: .875rem;
        padding-right: .875rem;
        font-weight: $font-weight-bold;
        background-color: $blue-100;
       
        &:hover, 
        &:focus {
            color: #fff !important;
            border-color: $primary;
            background-color: shade-color($primary, $btn-hover-bg-shade-amount) !important;
        }

        &:focus-visible {
            outline: 0;

            @if $enable-shadows {
                box-shadow: $btn-box-shadow,
            }

            @else {
                box-shadow: $btn-focus-box-shadow,
            }
        }
    }
    .form-control {
        text-align: center;
        border-color: transparent;
        max-width: 3.5rem;
    }
}


.form-check-select {
    padding-left: 0;

    .form-check-input {
        @include visually-hidden;
        &:focus ~ .form-check-label {
            background-color: $teal-100;
            box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width  rgba(white, $input-btn-focus-color-opacity);
        }
        &:checked ~ .form-check-label {
            background-color: #01CFD2;
            &::after {
                visibility: visible;
            }
        }
    }

    .form-check-label {
        cursor: pointer;
        background-color: $white;

        &:hover {
            background-color: $teal-100;
        }

        &::after {
            content: "";
            width: 1.875rem;
            height: 1.875rem;
            border-radius: 50%;
            margin-left: auto;
            visibility: hidden;
            background: $white escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$primary}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>")) center/ 1.25rem no-repeat;
        }
    }
}

.btn-search-icon {
    z-index: 5 !important;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 !important;
}
.colage-fig {
    overflow: hidden;
    border-radius: $border-radius-lg;
    margin-bottom: 0;
    flex: 1 1;
}
.colage-img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: center;
}

.colage-col-1 {
    .colage-fig:first-child {
        max-height: 40%;
        margin-bottom: 1.5rem;
    }
} 
.colage-col-1,
.colage-col-2 {
    min-height: 320px;
    max-height: 420px;
}
.avatar {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  margin-bottom: 0;
  flex-shrink: 0;
  font-size: 1.5rem;
  border-radius: $border-radius-pill;
//   border-radius: 50%;
  overflow: hidden;
  object-fit: cover;

  &-initials,
  &-img,
  &-white,
  &-transparent {
    display: inline-flex;
    text-align: center;
    color: #fff;
    font-style: normal;
    background-color: $primary;
    width: 100%;
    height: 100%;
    // border-radius: $border-radius-pill;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    border: 3px solid transparent;
    transition: border 0.15s, color 0.15s, background-color 0.15s;
  }
  &-initials {
    border-color: $primary;
  }

  &-img {
    border-color: $pink;
  }

  &-transparent {
    border-color: transparent;
  }

  &-white {
    border-color: $white;
  }

  &-black {
    border-color: $black;
  }
}
.avatar-md {
  font-size: 1.25rem;
}

.avatar-lg {
  font-size: 2rem;
}

.avatar-group {
  > .avatar:not(:first-child) {
    margin-left: -0.875rem;
  }
  > .avatar:last-of-type {
    margin-right: 1rem;
  }
}

body {
  overflow-x: hidden;
}

.skip-navigation {
  border: 0 !important;
  height: 0.0625rem !important;
  overflow: hidden !important;
  padding: 2px !important;
  position: fixed !important;
  width: 0.0625rem !important;
  left: 50%;
  transform: translate(-50%, -100%);
  transition: transform 0.3s;
  z-index: $zindex-fixed + 1;
  font-weight: bold;

  &:focus {
    text-decoration: none;
    width: auto !important;
    height: auto !important;
    text-align: center;
    border: 0;
    transform: translate(-50%, 0%);
  }
}

.statistics {
  text-align: left;
  letter-spacing: 0px;
  color: #1e1b58;
  opacity: 1;
}

.statistics-numbers {
  text-align: left;

  letter-spacing: 0px;
  color: #d91a5b;
  opacity: 1;
}
.main-content {
  @include media-breakpoint-up("md") {
  }
}

.registrants-btn {
  padding: $btn-padding-y $btn-padding-x;
  border: 1px solid #00999b;
  color: #ffffff;
  height: 2.5rem;
  display: flex;
  align-items: center;
  border-radius: $border-radius-lg;
  background: #00999b 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.member-button {
  padding: $btn-padding-y $btn-padding-x;
  border: 1px solid #00999b;
  color: #ffffff;
  height: 2.5rem;
  display: flex;
  align-items: center;
  border-radius: $border-radius-lg;
  background: #00999b 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.registrants-all {
  background: #00999b29 0% 0% no-repeat padding-box;
  opacity: 1;
}

// .card {
//   padding: 1.5em 0.5em 0.5em;
//   text-align: center;
//   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
// }

// .avatar {
//   height: 50px !important;
//   width: 50px !important;
//   border-radius: 50%;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
//   border: 3px solid #d91a5b;
// }

.image-and-name {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.name-and-proff {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name-and-proff p {
  margin: 0 !important;
}

.viewall {
  letter-spacing: 0px;
  color: #00999b;
  text-transform: uppercase;
  opacity: 1;
  text-decoration: underline;
}

.latest-cdi-news {
  border-radius: 16px;
  height: 500px;
  width: 1200px;
}

.learn-btn {
  border: 1px solid #00999b;
  color: #ffffff;
  height: 2.5rem;
  align-items: center;
  border-radius: $border-radius-lg;
  background: #00999b 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.explore-cdi-background {
  background: transparent
    linear-gradient(119deg, #1e1b58 0%, #00999b 59%, #d91a5b 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
}

.card-img-book {
  height: 200px;
}

.cards-section-book {
  position: relative;
}

.absolute-section {
  position: absolute;
  top: -20px;
  left: 16px;
  background-color: #f7d0dd;
  padding: 10px 15px 8px 12px;
  color: #d91a5b;
  border-radius: 1rem;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.absolute-section2 {
  position: absolute;
  top: -20px;
  left: 16px;
  background-color: #d1d0dc;
  padding: 10px 15px 8px 12px;
  color: #1e1b58;
  border-radius: 1rem;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.absolute-section3 {
  position: absolute;
  top: -20px;
  left: 16px;
  background-color: #fae3d4;
  padding: 10px 15px 8px 12px;
  color: #f2833b;
  border-radius: 1rem;
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}

.lc-absolute {
  border-radius: 50%;
  width: 35px;
  position: absolute;
  background: #d91a5b 0% 0% no-repeat padding-box;
}

.red-circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #d91a5b 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-svg {
  text-align: left;
  letter-spacing: 0px;
  color: #000000cc;
  opacity: 1;
}

.last-chapters {
  background-image: url("pexels2.jpg");
  background-repeat: no-repeat;
}

.lc-svg {
  position: relative;
}

.banner-background {
  background: transparent
    linear-gradient(110deg, #1e1b58 0%, #00999b 59%, #d91a5b 100%) 0% 0%
    no-repeat padding-box;
}

.book-card-title {
  height: 6rem;
}

.explore-title {
  height: 40px;
}

.explore-text {
  height: 100px;
  padding: 12px;
}

.latest-cdi-news-pic {
  height: 25rem;
  width: 35rem;
}

.written_by_section_bg {
  background-color: #00999b29;
}

.btn-primary {
  background: #00999b;
  --bs-btn-hover-bg: #00999b;
}


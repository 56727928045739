

@media screen and (max-width: 480px) {
  .video-profile {
    width: 370px;
    height: 250px;
  }
  .profile-pic {
    width: 6rem !important;
  }
}

@keyframes fade-line {
    0% {
        opacity: 0;
        transform: scale3d(0,1,1)
    }

    10% {
        opacity: .14;
        transform: scale3d(.1,1,1)
    }
    100% {
        opacity: .8;
        transform: scale3d(1,1,1)
    }
}

@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translateY(2rem)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes fade-right-dropdown-menu {
    0% {
        opacity: 0;
        transform: translateX(-1rem)
    }

    100% {
        opacity: 1;
        transform: translateX(0)
    }
}